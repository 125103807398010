import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import typography from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { ImageFormContainerProps } from './DoeImageForm.consts';
import Modal from 'components/shared/modal/Modal';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import { StyledWithThemeProps } from 'utils/types';

export const StyledImagesFormModal = styled(Modal)`
  min-width: 950px;
  max-height: 90%;
`;
export const Forms = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: -${typography.unit * 5}px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const ImageFormContainer = styled.div<ImageFormContainerProps>`
  display: flex;
  flex: 1;
  gap: ${typography.unit * 4}px;
  padding: ${typography.unit * 3}px ${typography.unit * 2}px};
  border-radius: ${typography.borderRadius}px;
  margin-bottom: ${typography.unit * 3}px;
  position: relative;
`;

export const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${typography.unit * 3}px;
  margin-bottom: 20px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledControlledToggleSwitch = styled(ControlledToggleSwitch)`
  margin-bottom: ${typography.unit * 7}px;
`;

export const RemoveButton = styled(ButtonText)`
  position: absolute;
  top: ${typography.unit * 2}px;
  right: ${typography.unit * 2}px;
`;

export const RemoveIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
`;

export const StyledSelectbox = styled(Selectbox)`
  display: flex;
  width: 100%;
  margin-bottom: ${typography.unit * 7}px;
` as unknown as typeof Selectbox;

export const StyledListSelectbox = styled(Selectbox)`
  width: fit-content;
` as unknown as typeof Selectbox;

export const StyledTextField = styled(TextField)`
  min-width: 50px;
  input {
    width: 50px;
  }
`;

export const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
`;

export const PropName = styled.div<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 13px;
`;

export const PropValue = styled.div<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  white-space: nowrap;
`;
