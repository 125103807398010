import {
  CampaignProps,
  CampaignReportProps,
  LocalSchedule,
  PushNotification,
} from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { MarketConfiguration } from 'app/slices/config';
import { LanguageRecord } from './languages';
import { ApprovalStatus, DateTimeConfig } from './types';
import { convert24To12HoursFormat, separateDateTime } from './date';

export const handleCampaignImpactChange = (campaigns: CampaignProps[]) => {
  return campaigns?.map((campaign: CampaignProps) => ({
    id: campaign?.externalId ? campaign.externalId : '-',
    externalId: campaign?.externalId ? campaign.externalId : '-',
    entityId: campaign?.externalId ? campaign.externalId : '-',
    title: campaign?.title,
    status: campaign.status === ApprovalStatus.AssociationStopped ? ApprovalStatus.Active : campaign.status,
    type: campaign?.type,
  }));
};

export const generateColumnNames = (primaryLanguage: string, secondaryLanguage: string) => {
  return [
    [
      `Schedule Name`,
      `Schedule Start`,
      `Schedule End`,
      `Schedule Status`,
      `Zone`,
      `Campaign ID`,
      `Campaign Title`,
      `Campaign Type`,
      `Campaign Start Date`,
      `Campaign Start Time`,
      `Campaign End Date`,
      `Campaign End Time`,
      `Campaign Status`,
      `Offer ID`,
      `Offer Title`,
      `Offer Subtitle`,
      `Offer Validity Start`,
      `Offer Validity End`,
      `Push Notification Title ${LanguageRecord[primaryLanguage]}`,
      `Push Notification Message ${LanguageRecord[primaryLanguage]}`,
      `Push Notification Title ${LanguageRecord[secondaryLanguage]}`,
      `Push Notification Message ${LanguageRecord[secondaryLanguage]}`,
      `Push Notification Date`,
      `Push Notification Time`,
    ],
  ];
};

export const extractCampaignPushNotificationFields = (
  campaignPushNotification: PushNotification,
  config: MarketConfiguration,
  dateTimeConfig: DateTimeConfig,
) => {
  if (campaignPushNotification) {
    const { push_notification_translation } = campaignPushNotification ?? {};
    const [primaryTranslation, secondaryTranslation] = [
      push_notification_translation?.find((translation) => translation.language === config.primaryLanguage),
      push_notification_translation?.find((translation) => translation.language === config.secondaryLanguage),
    ];
    return [
      primaryTranslation?.title ?? '',
      primaryTranslation?.message ?? '',
      secondaryTranslation?.title ?? '',
      secondaryTranslation?.message ?? '',
      ...separateDateTime(campaignPushNotification?.startDate, dateTimeConfig, config.startTimezone),
    ];
  } else {
    return ['', '', '', '', '', ''];
  }
};

export const extractCampaignLocalScheduleFields = (
  campaignLocalSchedule: LocalSchedule,
  dateTimeConfig: DateTimeConfig,
) => {
  if (campaignLocalSchedule) {
    return [
      campaignLocalSchedule?.period?.name ?? '',
      separateDateTime(campaignLocalSchedule?.period?.startDate, dateTimeConfig, dateTimeConfig.startTimezone)[0],
      separateDateTime(campaignLocalSchedule?.period?.endDate, dateTimeConfig, dateTimeConfig.endTimezone)[0],
      campaignLocalSchedule?.approvalStatus ?? '',
      campaignLocalSchedule?.zone?.name ?? (campaignLocalSchedule?.period ? 'National' : ''),
    ];
  } else {
    return ['', '', '', '', ''];
  }
};

export const generateCampaignRow = (
  campaign: CampaignReportProps,
  dateTimeConfig: DateTimeConfig,
  config: MarketConfiguration,
) => {
  return [
    ...extractCampaignLocalScheduleFields(campaign?.localSchedule, dateTimeConfig),
    campaign?.externalId ?? '',
    campaign?.title ?? '',
    campaign?.type ?? '',
    ...separateDateTime(campaign?.schedule?.campaignStart, dateTimeConfig, config.startTimezone),
    ...separateDateTime(campaign?.schedule?.campaignEnd, dateTimeConfig, config.endTimezone),
    campaign?.status ?? '',
    campaign?.offerVersion?.baseOfferId ?? '',
    campaign?.offerVersion?.translationsMap[config.primaryLanguage]?.title ?? '',
    campaign?.offerVersion?.translationsMap[config.primaryLanguage]?.subtitle ?? '',
    convert24To12HoursFormat(campaign?.schedule?.validityStartTime) ?? '',
    convert24To12HoursFormat(campaign?.schedule?.validityEndTime) ?? '',
    ...extractCampaignPushNotificationFields(campaign?.push_notification, config, dateTimeConfig),
  ];
};
