import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextArea from 'components/shared/textArea/TextArea';
import { StyledWithThemeProps } from 'utils/types';
import Banner from 'components/shared/notifications/banner/Banner';

export const ImageContainer = styled.div<StyledWithThemeProps>`
  width: 252px;
  height: 309px;
  background-color: ${({ theme }) => theme.colors.global.imageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  word-break: break-all;
`;

export const ImagesFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${typography.unit * 3}px;
`;

export const ImageName = styled(TextField)`
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 7}px;
`;

export const ImageTags = styled(Selectbox)`
  display: flex;
  width: 100%;
  margin-bottom: ${typography.unit * 7}px;
` as unknown as typeof Selectbox;

export const ImageDescription = styled(TextArea)`
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 7}px;
`;

export const StyledButtonsContainer = styled.div<{marginTop ?: number;}>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ marginTop }) => `${(marginTop || 7)*typography.unit}px`};
  gap: ${typography.unit * 5}px;
`;

export const StyledImagesBanner = styled(Banner)<StyledWithThemeProps>`
  margin-bottom: ${typography.unit * 3}px;
`;
