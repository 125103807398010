import styled from '@emotion/styled';
import MultipleChoice from 'components/shared/multipleChoose/MultipleChoice';
import typography from 'styles/typography';
import { DateTimePicker } from 'components/shared/picker/datetimePicker/DateTimePicker';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from 'components/shared/icon';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import NumberTextField from 'components/shared/textField/NumberTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 530px;
`;

export const MultipleDateRow = styled.div`
  display: flex;
  width: 257px;
  margin: 0;
  margin-right: 8px;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 530px;
`;

export const CampaignDateSection = styled(Section)`
  margin-bottom: 24px; 
`
export const RecurrenceSection = styled(Section)`
  margin-bottom: 24px; 
  padding-left: 2px;
`;

export const RowSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 530px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin-top: 16px; 
`

export const HeadingRowSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 530px;
  height: 24px;
  margin-bottom: 8px;
`;

export const RedemptionRow = styled.div`
  width: 530px;
  margin-bottom: 16px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)<{version?: string, disabled?: boolean}>`
  margin-right: ${typography.unit * 5}px;
  width: 257px;
   label {
    font-size: 12px; 
    margin-bottom: 8px; 
  }
  .MuiInputBase-root:before {
      ${({disabled, version}) => disabled && version === 'campaign-form' && 'border-bottom-color: #ADADAD; :hover { border-bottom-color: #ADADAD; } '}
  }
  .MuiInputBase-root {
    height: 36px; 
     input {
      font-size: 12px; 
  }
  }

  [data-automation-id~='error-message'] {
    font-size: 10px;
    margin-top: 29px;
  }
`;

export const StyledTimePicker = styled(TimePicker)<{version?: string, disabled?: boolean}>`
  margin-right: ${typography.unit * 5}px;
  width: 257px;
  label {
    font-size: 12px; 
    margin-bottom: 8px; 
  }
  .MuiInputBase-root:before {
      ${({disabled, version}) => disabled && version === 'campaign-form' && 'border-bottom-color: #ADADAD; :hover { border-bottom-color: #ADADAD; }'}
  }

  .MuiInputBase-root {
    height: 36px; 
    input {
      font-size: 12px; 
  }
  }
  .error-message {
    font-size: 14px; 
    color: black;
    margin-top: 29px;
  }
  
`;

export const ScheduleContainer = styled.div<{ hasExtraMargin?: boolean, margin?: number}>`
  display: flex;
  margin-bottom: ${({ hasExtraMargin }) => (hasExtraMargin && `${typography.unit * 7}px` )};
  margin-bottom: ${({ margin }) => (margin && `${margin}px`)};
  div:last-of-type {
    margin-right: 0;
  }
`;

export const StyledMultipleChoice = styled(MultipleChoice)`
  margin-right: ${typography.unit * 5}px;
  label {
    font-size: 12px; 
    font-weight: 700; 
    margin-bottom: 0px; 
  }
  ul {
    padding-top: 16px; 
    padding-bottom: 0px; 
  }

`;

export const ExpirationDate = styled(OfferFormTextField)`
  width:257px;
  margin-bottom: 0px; 
  margin-top: 24px; 
  input {
    width: 50px;
  }

  div[data-automation-id="error-message"] {
    color: #D90007 !important;
    margin-top: 39px;
  }
`;

export const RecurrenceRow = styled.div`
  width: 530px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubHeader = styled.div`
  width: 530px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  gap: 0px;
  opacity: 0px;
  display: flex;
  margin-bottom: 8px;
`;

export const RecurrenceSubheader = styled(SubHeader)`
  margin-bottom: 0px; 
`;

export const CampaignSubHeader = styled.div`
  gap: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  opacity: 0px;
  display: flex;
  margin-bottom: 0px; 
`;

export const MultipleDatesDiv = styled.div``;
export const ToggleTitle = styled.div<{disabled?: boolean}>`
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-right: ${typography.unit * 2}px;
  ${({disabled}) => disabled && `color: ${newGlobalTheme.colors.text.disabledSave};`}
`;

export const RecurrenceSelect = styled(NewSelectbox)`
  margin-right: 16px;
  [data-automation-id~='error-message'] {
    top: 39px;
  }
`;

export const Redemption = styled.div<StyledWithThemeProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.text.primary};
  align-items: center;
`;

export const NumberField = styled(NumberTextField)`
  margin-right: 16px;
  width: 195px;
  font-size: 12px;
  label {
    font-family: Speedee;
    font-size: 12px;
    font-weight: 700;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    color: ${newGlobalTheme.colors.text.primary};
  }

  input {
    font-size: 12px;
    ::placeholder {
      font-size: 12px;
    }
  }

  .input-wrapper {
    width: 195px;
    border-color: ${newGlobalTheme.colors.global.borderColorboxes};
    :hover {
     border-color: ${newGlobalTheme.colors.global.borderColorboxes};
    }
    :focus-within {
      border-color: ${newGlobalTheme.colors.global.borderColorboxes};
    }
  }
`;

export const RedemptionNumberField = styled(NumberTextField)<{hasError?: boolean, disabled?: boolean}>`
  margin-right: 23px;
  height: 36px; 
  label {
    margin-right: 23px;
    font-family: Speedee;
    font-size: 12px;
    font-weight: 500;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    color: ${newGlobalTheme.colors.text.primary};
  }
    div[role='alert'] {
        color: #D90007 !important; 
        margin-top: 39px; 
    }
    input {
        font-size: 12px; 
        height: 36px; 
    }
  .input-wrapper {
    width: 105.5px;
    height: 36px; 
    margin-right: 16px; 
    :focus-within {
        border-color: ${({hasError}) => hasError ? newGlobalTheme.colors.global.errorTextColor: newGlobalTheme.colors.global.borderColorboxes};
      }
    border-color: ${({hasError}) => hasError ? newGlobalTheme.colors.global.errorTextColor: newGlobalTheme.colors.global.borderColorboxes};
    ${({disabled}) => disabled && `border-color: #ADADAD; color: ${newGlobalTheme.colors.text.disabledSave};`}
    }
`;

export const RedemptionsText = styled.span<StyledWithThemeProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-right:40px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const AddLimitText = styled.span<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.textSubheading};
  margin-left: 8px;
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
  fill: ${newGlobalTheme.colors.global.textSubheading};
  margin: ${typography.unit * 4}px 0;
  align-self: center;
`;

export const StyledRemoveIcon = styled(StyledIcon)`
`;

export const StyledEditIcon = styled(StyledIcon)`
  margin: 0 ${typography.unit * 3}px 0 0;
`;

export const BulkSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${typography.unit}px;
`;

export const MultipleDatesToggle = styled(ToggleSwitch)`
  margin-bottom: ${typography.unit}px;
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  align-items: center;
  font-family: Speedee;
  font-size: 12px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
`;

export const MultipleDatesSection = styled.div`
  display: flex;
  width: 530px;
  align-items: center;
`;

export const DatesSelection = styled.div`
  display: flex;
  width: 148px;
  align-items: center;
  margin-left: 16px;
`;

export const DurationSelection = styled.div`
  width: 257px;
  align-items: left;
  display: flex;
`;

export const PushAndRecurrenceWrapper = styled.div`
  margin-top: 24px; 
`

export const PeriodSection = styled(Section)`
    div[data-automation-id="error"] {
        margin-left: 50px; 
    }
    margin-bottom: 24px; 
    

`
