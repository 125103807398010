import React, { useEffect, useState } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import {
  EnterValueWrapper,
  ProductPicker,
  StyledBuySelectbox,
  StyledCheckbox,
  StyledForSelectbox,
  StyledLabel,
  StyledLightLabel,
} from '../Template.style';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { ProductSetType } from 'utils/types';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';
import { checkForEmptyData } from '../shared/ProductsActionCondition.utils';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { getIsControlWithError } from 'utils/form';

const getWidth = (truthValue: number, falseValue: number, discountTypeCondition = true) => {
  return discountTypeCondition ? truthValue : falseValue;
};

const NonProductPLUsSection = ({
  setIncludeNonProduct,
  includeNonFoodProductCodes,
  disabled,
  includeNonProduct,
  selectProducts,
  getValues,
  onProductSelection,
  offerID,
  register,
  templateValuesIncludeNonFoodProductCodesPath,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper gap={8}>
      <StyledLabel>Non-Product PLUs to Include</StyledLabel>
      <ProductPicker>
        <ProductLine>{calcProductsHint(includeNonFoodProductCodes)}</ProductLine>
        {getProductsAction(
          disabled || includeNonProduct,
          includeNonFoodProductCodes,
          getValues,
          selectProducts,
          'includeNonFoodProductCodes',
          (data: any) => ({
            includeNonFoodProductCodes: !checkForEmptyData(data) ? data : undefined,
          }),
          onProductSelection,
          true,
          ProductSetType.NonFoodProduct,
          null,
          offerID,
          onProductPreviewClick,
        )}
        <input
          type="hidden"
          {...register(templateValuesIncludeNonFoodProductCodesPath, {
            value: includeNonFoodProductCodes,
            shouldUnregister: true,
            setValueAs: (v: any) => (v === '' ? undefined : v),
          })}
        />
      </ProductPicker>
      <StyledCheckbox
        checked={includeNonProduct}
        disabled={disabled}
        offerTemplate
        onClick={() => {
          setIncludeNonProduct(!includeNonProduct);
          store.dispatch(
            setData({
              data: {
                templateProducts: {
                  includeNonFoodProductCodes: includeNonProduct ? undefined : includeNonFoodProductCodes,
                },
                isFormDirty: true,
              },
            }),
          );
        }}
        label="Include All"
      />
    </LineWrapper>
  );
};

const GetCondition = ({
  control,
  disabled,
  get,
  products,
  getValues,
  selectProducts,
  onProductSelection,
  offerID,
  register,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper gap={8}>
      <StyledLabel>Get</StyledLabel>
      <StyledLightLabel>Up to</StyledLightLabel>
      <StyledBuySelectbox
        control={control}
        name="versions.0.templateValues.upToLimit"
        disabled={disabled}
        validation={{
          min: 1,
          max: 15,
          required: true,
        }}
        items={Array.from(
          { length: 15 },
          (x, i) =>
            ({
              id: (i + 1).toString(),
              name: (i + 1).toString(),
            } as any),
        )}
        defaultValue="1"
        initialSelectedItems={get ? [typeof get === 'object' ? get.id : get] : ['1']}
        selectWidth={60}
        version="offer-form"
      />
      <ProductPicker>
        <ProductLine>{calcProductsHint(products)}</ProductLine>
        {getProductsAction(
          disabled,
          products,
          getValues,
          selectProducts,
          'products',
          (data: any) => ({
            products: data,
          }),
          onProductSelection,
          true,
          null,
          null,
          offerID,
          onProductPreviewClick,
        )}
        <input
          type="hidden"
          {...register('versions.0.templateValues.products', {
            value: products,
            shouldUnregister: true,
            required: true,
          })}
        />
      </ProductPicker>
    </LineWrapper>
  );
};

export const PriceDeal = ({
  disabled,
  onProductSelection,
  offerID,
  offerSource,
  mode,
  onProductPreviewClick,
}: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectProducts = 'Select Products';
  const doeDisable = disabled || (offerSource === OfferSource.DOE && mode === 'edit');
  const conditionValueString = 'versions.0.templateValues.conditionValue';
  const templateValuesIncludeNonFoodProductCodesPath = 'versions.0.templateValues.includeNonFoodProductCodes';
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const [discountCondition, products, excludeCodes, get, includeNonFoodProductCodes] = watch([
    'versions.0.templateValues.discountCondition',
    'versions.0.templateValues.products',
    'versions.0.templateValues.excludeCodes',
    'versions.0.templateValues.upToLimit',
    templateValuesIncludeNonFoodProductCodesPath,
  ]);
  const [includeNonProduct, setIncludeNonProduct] = useState(watch('versions.0.templateValues.includeNonProduct'));

  useEffect(() => {
    setValue('versions.0.templateValues.includeNonProduct', includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValuesIncludeNonFoodProductCodesPath, undefined);
    }
  }, [includeNonProduct]);

  return (
    <OfferInfoWrapper disabled={disabled}>
      <GetCondition
        control={control}
        disabled={disabled}
        get={get}
        products={products}
        getValues={getValues}
        selectProducts={selectProducts}
        onProductSelection={onProductSelection}
        offerID={offerID}
        onProductPreviewClick={onProductPreviewClick}
        register={register}
      />
      <LineWrapper gap={8}>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={doeDisable}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={getWidth(95, 85, discountType === DiscountType.FixedPrice)}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
          version="offer-form"
        />
        {!isFree && (
          <>
            <EnterValueWrapper
              disabled={disabled}
              errors={getIsControlWithError('versions.0.templateValues.discountValue', errors)}
            >
              {shouldDisplayCurrency && <Currency />}
              <OfferTemplateTextField
                disabled={doeDisable}
                register={register}
                validation={{
                  required: true,
                  min: isPercent ? 1 : 0.01,
                  max: isPercent ? 99 : null,
                  pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                  validate: (v: string) =>
                    (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
                }}
                errors={errors}
                name="versions.0.templateValues.discountValue"
                placeholder="Enter value"
                selectWidth={75}
              />
            </EnterValueWrapper>
          </>
        )}
        <>
          <StyledLightLabel>with</StyledLightLabel>
          <StyledForSelectbox
            name="versions.0.templateValues.discountCondition"
            control={control}
            disabled={doeDisable}
            validation={{
              required: true,
            }}
            items={
              Object.entries(DiscountConditionRecord)
                .filter(([key]) => key !== DiscountCondition.None && key !== DiscountCondition.WithPurchaseOf)
                .map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
            }
            defaultValue={DiscountCondition.MinimumPurchase}
            initialSelectedItems={
              discountCondition && discountCondition !== DiscountCondition.None
                ? [discountCondition]
                : [DiscountCondition.MinimumPurchase]
            }
            selectWidth={getWidth(136, 120, discountCondition === DiscountCondition.MinimumPurchase)}
            version="offer-form"
          />
          {discountCondition === DiscountCondition.MinimumPurchase && (
            <>
              <StyledLightLabel>of</StyledLightLabel>
              <EnterValueWrapper
                width={discountType === DiscountType.FixedPrice ? 75 : 85}
                disabled={disabled}
                errors={getIsControlWithError(conditionValueString, errors)}
              >
                <Currency />
                <OfferTemplateTextField
                  disabled={doeDisable}
                  register={register}
                  validation={{
                    required: true,
                    min: 0.01,
                    max: null,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                  }}
                  errors={errors}
                  name="versions.0.templateValues.conditionValue"
                  placeholder="Enter value"
                  selectWidth={getWidth(70, 80, discountType === DiscountType.FixedPrice)}
                />
              </EnterValueWrapper>
            </>
          )}
        </>
      </LineWrapper>
      <NonProductPLUsSection
        setIncludeNonProduct={setIncludeNonProduct}
        includeNonFoodProductCodes={includeNonFoodProductCodes}
        disabled={disabled}
        includeNonProduct={includeNonProduct}
        selectProducts={selectProducts}
        getValues={getValues}
        onProductSelection={onProductSelection}
        offerID={offerID}
        register={register}
        templateValuesIncludeNonFoodProductCodesPath={templateValuesIncludeNonFoodProductCodesPath}
        onProductPreviewClick={onProductPreviewClick}
      />
      {(discountCondition === DiscountCondition.MinimumPurchase ||
        discountCondition === DiscountCondition.WithAnyPurchase) && (
        <LineWrapper gap={8}>
          <StyledLabel>Exclude</StyledLabel>
          <ProductPicker>
            <ProductLine>{calcProductsHint(excludeCodes)}</ProductLine>
            {getProductsAction(
              disabled,
              excludeCodes,
              getValues,
              selectProducts,
              'excludeCodes',
              (data: any) => ({
                excludeCodes: !checkForEmptyData(data) ? data : undefined,
              }),
              onProductSelection,
              true,
              null,
              null,
              offerID,
              onProductPreviewClick,
            )}
            <input
              type="hidden"
              {...register('versions.0.templateValues.excludeCodes', {
                value: excludeCodes,
                shouldUnregister: true,
                setValueAs: (v) => (v === '' ? undefined : v),
              })}
            />
          </ProductPicker>
        </LineWrapper>
      )}
    </OfferInfoWrapper>
  );
};
